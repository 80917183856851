import { CartEvent, CART_BASE_FEATURE, ORDER_ENTRIES_CONTEXT } from '@spartacus/cart/base/root';
import * as i0 from '@angular/core';
import { Injectable, InjectionToken, NgModule } from '@angular/core';
import { facadeFactory, provideDefaultConfigFactory, provideDefaultConfig, AuthGuard } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import * as i1 from '@angular/router';
import { RouterModule } from '@angular/router';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * Base saved cart event. Most cart events should have these properties.
 */
class SavedCartEvent extends CartEvent {}
// =====================================================================
class SaveCartEvent extends SavedCartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'SaveCartEvent';
  }
}
class SaveCartSuccessEvent extends SavedCartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'SaveCartSuccessEvent';
  }
}
class SaveCartFailEvent extends SavedCartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'SaveCartEvent';
  }
}
class RestoreSavedCartEvent extends SavedCartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'RestoreSavedCartEvent';
  }
}
class RestoreSavedCartSuccessEvent extends SavedCartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'RestoreSavedCartSuccessEvent';
  }
}
class RestoreSavedCartFailEvent extends SavedCartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'RestoreSavedCartFailEvent';
  }
}
class EditSavedCartEvent extends SavedCartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'EditSavedCartEvent';
  }
}
class EditSavedCartSuccessEvent extends SavedCartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'EditSavedCartSuccessEvent';
  }
}
class EditSavedCartFailEvent extends SavedCartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'EditSavedCartFailEvent';
  }
}
class CloneSavedCartEvent extends SavedCartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'CloneSavedCartEvent';
  }
}
class CloneSavedCartSuccessEvent extends SavedCartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'CloneSavedCartSuccessEvent';
  }
}
class CloneSavedCartFailEvent extends SavedCartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'CloneSavedCartFailEvent';
  }
}

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const CART_SAVED_CART_FEATURE = 'cartSavedCart';
const CART_SAVED_CART_CORE_FEATURE = 'cartSavedCartCore';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class SavedCartFacade {
  static {
    this.ɵfac = function SavedCartFacade_Factory(t) {
      return new (t || SavedCartFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SavedCartFacade,
      factory: () => (() => facadeFactory({
        facade: SavedCartFacade,
        feature: CART_SAVED_CART_CORE_FEATURE,
        methods: ['editSavedCart', 'deleteSavedCart', 'getSavedCart', 'getSavedCartList', 'loadSavedCart', 'clearCloneSavedCart', 'clearRestoreSavedCart', 'clearSaveCart', 'clearSavedCarts', 'get', 'getList', 'getCloneSavedCartProcessError', 'getCloneSavedCartProcessLoading', 'getCloneSavedCartProcessSuccess', 'getRestoreSavedCartProcessError', 'getRestoreSavedCartProcessLoading', 'getRestoreSavedCartProcessSuccess', 'getSaveCartProcessError', 'getSaveCartProcessLoading', 'getSaveCartProcessSuccess', 'getSavedCartListProcess', 'getSavedCartListProcessLoading', 'isStable', 'cloneSavedCart', 'loadSavedCarts', 'restoreSavedCart', 'saveCart'],
        async: true
      }))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SavedCartFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => facadeFactory({
        facade: SavedCartFacade,
        feature: CART_SAVED_CART_CORE_FEATURE,
        methods: ['editSavedCart', 'deleteSavedCart', 'getSavedCart', 'getSavedCartList', 'loadSavedCart', 'clearCloneSavedCart', 'clearRestoreSavedCart', 'clearSaveCart', 'clearSavedCarts', 'get', 'getList', 'getCloneSavedCartProcessError', 'getCloneSavedCartProcessLoading', 'getCloneSavedCartProcessSuccess', 'getRestoreSavedCartProcessError', 'getRestoreSavedCartProcessLoading', 'getRestoreSavedCartProcessSuccess', 'getSaveCartProcessError', 'getSaveCartProcessLoading', 'getSaveCartProcessSuccess', 'getSavedCartListProcess', 'getSavedCartListProcessLoading', 'isStable', 'cloneSavedCart', 'loadSavedCarts', 'restoreSavedCart', 'saveCart'],
        async: true
      })
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
var SavedCartFormType;
(function (SavedCartFormType) {
  SavedCartFormType["EDIT"] = "edit";
  SavedCartFormType["DELETE"] = "delete";
  SavedCartFormType["SAVE"] = "save";
  SavedCartFormType["RESTORE"] = "restore";
})(SavedCartFormType || (SavedCartFormType = {}));

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const SavedCartOrderEntriesContextToken = new InjectionToken('SavedCartOrderEntriesContext');
const NewSavedCartOrderEntriesContextToken = new InjectionToken('NewSavedCartOrderEntriesContext');

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
// TODO: Inline this factory when we start releasing Ivy compiled libraries
function defaultCartSavedCartComponentsConfig() {
  const config = {
    featureModules: {
      [CART_SAVED_CART_FEATURE]: {
        cmsComponents: ['AddToSavedCartsComponent', 'AccountSavedCartHistoryComponent', 'SavedCartDetailsOverviewComponent', 'SavedCartDetailsItemsComponent', 'SavedCartDetailsActionComponent'],
        dependencies: [CART_BASE_FEATURE]
      },
      // by default core is bundled together with components
      [CART_SAVED_CART_CORE_FEATURE]: CART_SAVED_CART_FEATURE
    }
  };
  return config;
}
class SavedCartRootModule {
  static {
    this.ɵfac = function SavedCartRootModule_Factory(t) {
      return new (t || SavedCartRootModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SavedCartRootModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfigFactory(defaultCartSavedCartComponentsConfig), provideDefaultConfig({
        routing: {
          routes: {
            savedCarts: {
              paths: ['my-account/saved-carts']
            },
            savedCartsDetails: {
              paths: ['my-account/saved-cart/:savedCartId'],
              paramsMapping: {
                savedCartId: 'savedCartId'
              }
            }
          }
        }
      })],
      imports: [RouterModule.forChild([{
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'savedCartsDetails',
          cxContext: {
            [ORDER_ENTRIES_CONTEXT]: SavedCartOrderEntriesContextToken
          }
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'savedCarts',
          cxContext: {
            [ORDER_ENTRIES_CONTEXT]: NewSavedCartOrderEntriesContextToken
          }
        }
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SavedCartRootModule, [{
    type: NgModule,
    args: [{
      imports: [RouterModule.forChild([{
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'savedCartsDetails',
          cxContext: {
            [ORDER_ENTRIES_CONTEXT]: SavedCartOrderEntriesContextToken
          }
        }
      }, {
        // @ts-ignore
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'savedCarts',
          cxContext: {
            [ORDER_ENTRIES_CONTEXT]: NewSavedCartOrderEntriesContextToken
          }
        }
      }])],
      providers: [provideDefaultConfigFactory(defaultCartSavedCartComponentsConfig), provideDefaultConfig({
        routing: {
          routes: {
            savedCarts: {
              paths: ['my-account/saved-carts']
            },
            savedCartsDetails: {
              paths: ['my-account/saved-cart/:savedCartId'],
              paramsMapping: {
                savedCartId: 'savedCartId'
              }
            }
          }
        }
      })]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CART_SAVED_CART_CORE_FEATURE, CART_SAVED_CART_FEATURE, CloneSavedCartEvent, CloneSavedCartFailEvent, CloneSavedCartSuccessEvent, EditSavedCartEvent, EditSavedCartFailEvent, EditSavedCartSuccessEvent, NewSavedCartOrderEntriesContextToken, RestoreSavedCartEvent, RestoreSavedCartFailEvent, RestoreSavedCartSuccessEvent, SaveCartEvent, SaveCartFailEvent, SaveCartSuccessEvent, SavedCartEvent, SavedCartFacade, SavedCartFormType, SavedCartOrderEntriesContextToken, SavedCartRootModule, defaultCartSavedCartComponentsConfig };
